var React = require('react');

class PointsList extends React.Component {
    render() {
        return <div className="sellers">
            {this.props.points.length < 1500 && this.props.points.map((item, i) => <div className="sellers__item" key={i}>
                <header className="sellers__header">
                    <div className="sellers__name">{item.name}</div>
                    <span
                        className="sellers__maplink"
                        onClick={this.props.onPointLinkClick.bind(null, i)}
                    >
						Показать на карте
					</span>
                </header>
                <div className="sellers__adr">{item.info}</div>
                <div className="sellers__url">
                    <a href={item.link} target="_blank">{item.link}</a>
                </div>
            </div>)}
        </div>
    }
}

PointsList.propTypes = {
    points : React.PropTypes.arrayOf(
        React.PropTypes.shape({
            name : React.PropTypes.string,
            info : React.PropTypes.string,
            link : React.PropTypes.string,
            coordinates : React.PropTypes.arrayOf(React.PropTypes.number)
        })
    ),
    onPointLinkClick : React.PropTypes.func
};

module.exports = PointsList;