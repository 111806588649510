var React = require('react');
var Form = require('./Form.jsx');
var PointsList = require('./PointsList.jsx');
var PointsMap = require('./PointsMap.jsx');

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            points : $.extend(true, [], props.data.bootstrapPoints),
            targetPoint : null
        }
    }

    receivePoints(points) {
        this.setState({
            points,
            targetPoint : null
        })
    }

    pointLinkClick(targetPoint) {
        $('html, body').scrollTop($(this.refs.map).offset().top - 50);
        this.setState({
            targetPoint
        });
    }

    render() {
        return <div>
            <Form
                countries={this.props.data.countries}
                onReceivePoints={this.receivePoints.bind(this)}
                userLocation={this.props.userLocation}
            />
            <div ref="map">
                <PointsMap points={this.state.points} targetPoint={this.state.targetPoint} />
            </div>
            <PointsList points={this.state.points} onPointLinkClick={this.pointLinkClick.bind(this)} />
        </div>
    }
}

module.exports = App;