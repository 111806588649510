"use strict";
require('swiper');

module.exports = function () {

	$('.related__slider').siblings('.related__header').prepend('<div class="related__nav"><div class="related__prev" /><div class="related__next" /></div>');
	$('.related__slider').swiper({
		slidesPerView: 'auto',
		speed: 500,
		prevButton: '.related__prev',
		nextButton: '.related__next',
		simulateTouch: false
	});
};