var React = require('react');

class Form extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id : props.userLocation.id,
            retail : false,
            wholesale : false,
            shops : false,
            activeCountry : props.userLocation.countryId,
            activeRegion : -1, //TODO: скорее всего дело здесь
            activeCity : props.userLocation.provinceId,
            loading : false
        }
    }

    countrySelect(event) {
        var activeCountry = +event.target.value;

        this.setState({
            id : activeCountry > -1
                ? this.props.countries[activeCountry].id
                : null,
            activeCountry,
            activeRegion : -1,
            activeCity : -1
        });
    }

    regionSelect(event) {
        var activeRegion = +event.target.value;

        this.setState({
            id : activeRegion > -1
                ? this.props.countries[this.state.activeCountry].regions[activeRegion].id
                : this.props.countries[this.state.activeCountry].id,
            activeRegion,
            activeCity : -1
        });
    }

    citySelect(event) {
        var activeCity = +event.target.value;

        this.setState({
            id : activeCity > -1
                ? this.state.activeRegion > -1
                    ? this.props.countries[this.state.activeCountry].regions[this.state.activeRegion].cities[activeCity].id
                    : this.props.countries[this.state.activeCountry].cities[activeCity].id
                : this.state.activeRegion > -1
                    ? this.props.countries[this.state.activeCountry].regions[this.state.activeRegion].id
                    : this.props.countries[this.state.activeCountry].id,
            activeCity
        });
    }

    submit(event) {
        event.preventDefault();

        if(!this.state.loading) {
            $.get('/ajax/map.php', {
                action : 'select',
                id : this.state.id,
                retail : this.state.retail,
                wholesale : this.state.wholesale,
                shops : this.state.shops
            }, 'json')
                .then((points)=>{
                    if(!Array.isArray(points)) return;
                    this.props.onReceivePoints(points);
                })
                .always(() => {
                    this.setState({
                        loading : false
                    });
                });
            this.setState({
                loading : true
            })
        }
    }

    render() {
        var countries = this.props.countries,
            regions = [],
            cities = [];

        if(countries[this.state.activeCountry]) {
            regions = countries[this.state.activeCountry].regions || regions;
            cities = countries[this.state.activeCountry].cities || cities;
        }

        if(regions[this.state.activeRegion]) {
            cities = regions[this.state.activeRegion].cities || cities;
        }

        return <form className="location" onSubmit={this.submit.bind(this)}>
            <div className="location__row">
                {!!countries.length && <div className="location__item">
                    <div className="location__label">Страна:</div>
                    <select
                        className="select"
                        value={this.state.activeCountry}
                        onChange={this.countrySelect.bind(this)}>
                        <option value={-1}>
                            Выберите
                        </option>
                        {countries.map((item, i) =>
                            <option value={i} key={i}>
                                {item.name}
                            </option>)}
                    </select>
                </div>}
                {!!regions.length && <div className="location__item">
                    <div className="location__label">Регио��:</div>
                    <select
                        className="select"
                        value={this.state.activeRegion}
                        onChange={this.regionSelect.bind(this)}>
                        <option value={-1}>
                            Выберите
                        </option>
                        {regions.map((item, i) =>
                            <option value={i} key={i}>
                                {item.name}
                            </option>)}
                    </select>
                </div>}
                {!!cities.length && <div className="location__item">
                    <div className="location__label">Город:</div>
                    <select
                        className="select"
                        value={this.state.activeCity}
                        onChange={this.citySelect.bind(this)}>
                        <option value={-1}>
                            Выберите
                        </option>
                        {cities.map((item, i) =>
                            <option value={i} key={i}>
                                {item.name}
                            </option>)}
                    </select>
                </div>}
            </div>
            <div className="location__row">
                <div className="location__item">
                    <div className="checkbox">
                        <input
                            className="checkbox__input"
                            type="checkbox"
                            id="retail"
                            checked={this.state.retail}
                            onChange={() => this.setState({retail : !this.state.retail})}
                        />
                        <label
                            className="checkbox__label"
                            htmlFor="retail">
                            Показывать розничные точки
                        </label>
                    </div>
                </div>
                <div className="location__item">
                    <div className="checkbox">
                        <input
                            className="checkbox__input"
                            type="checkbox"
                            id="wholesale"
                            checked={this.state.wholesale}
                            onChange={() => this.setState({wholesale : !this.state.wholesale})}
                        />
                        <label
                            className="checkbox__label"
                            htmlFor="wholesale">
                            Показывать оптовые точки
                        </label>
                    </div>
                </div>
                <div className="location__item location__item_last">
                    <div className="checkbox">
                        <input
                            className="checkbox__input"
                            type="checkbox"
                            id="shops"
                            checked={this.state.shops}
                            onChange={() => this.setState({shops : !this.state.shops})}
                        />
                        <label
                            className="checkbox__label"
                            htmlFor="shops">
                            Показывать интернет-магазины
                        </label>
                    </div>
                </div>
                <div className="location__item">
                    <button className="btn" type="submit">Показать</button>
                </div>
            </div>
        </form>;
    }
}

Form.propTypes = {
    countries : React.PropTypes.array,
    onReceivePoints : React.PropTypes.func,
    userLocation: React.PropTypes.shape({
        id: React.PropTypes.any,
        countryId: React.PropTypes.number,
        provinceId: React.PropTypes.number
    })
};

module.exports = Form;