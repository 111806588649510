"use strict";

module.exports = function () {
	$(window).on('resize', function(){
		if ($(window).width() < 768) {
			$('table:not(.responsive)').each(function(){
				var $table = $(this),
					thCount = $table.find('th').length;
				
				for (var i = 1; i <= thCount; i++) {
					var thText = $table.find('th:nth-child(' + i + ')').text();

					if (thText !== '') {
						$table.find('td:nth-child(' + i + ')')
							.prepend('<div class="responsive-th">' + thText + ':</div>')
							.addClass('responsive-td');
					}
				}
				$table.addClass('responsive');
			});
		} else {
			$('.responsive-th').remove();
			$('table.responsive').removeClass('responsive');
			$('td.responsive-td').removeClass('responsive-td');
		}
	}).resize();
};